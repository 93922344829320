import { FormControl, FormHelperText, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { FormikConfig, getIn, useFormik } from 'formik';
import { FC, useMemo } from 'react';
import { CreateIntegrationRequest } from '../../../api';
import { useConstants } from '../../../hooks/useConstants';

export type AmazonRelayFormProps = Omit<FormikConfig<CreateIntegrationRequest>, 'initialValues'> & {
  initialValues: CreateIntegrationRequest;
};

export const useAmazonRelayForm = (config: AmazonRelayFormProps) => {
  const { initialValues } = config;
  return useFormik({
    ...config,
    initialValues,
  });
};

const options: Record<string, string[]> = {
  local: ['What cities do you run?', 'Please enter all cities'],
  regional: ['What states/countries do you run?', 'Please enter all states/counties'],
  otr: ['What states/counties do you run', 'Please enter all states/counties'],
};

export const AmazonRelayForm: FC<ReturnType<typeof useAmazonRelayForm> & { isUpdating?: boolean }> = ({
  isUpdating,
  handleSubmit,
  values,
  handleChange,
  handleBlur,
  errors,
  touched,
}) => {
  const useConstantsQuery = useConstants();
  const [carrierDescLabel, carrierDescHelperText] = useMemo(() => {
    return options[values.settings.carrier_type];
  }, [values.settings.carrier_type]);

  const isError = (name: string) => {
    return !!getIn(errors, name) && !!getIn(touched, name);
  };

  const getError = (name: string) => {
    return isError(name) && getIn(errors, name);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Stack gap={2}>
        <FormControl fullWidth variant="outlined" size="small" error={isError('settings.domain')}>
          <InputLabel required>Amazon Relay Domain</InputLabel>
          <Select
            name="settings.domain"
            value={values.settings.domain}
            label="Amazon Relay Domain"
            onChange={handleChange}
            required
            disabled={isUpdating}
          >
            <MenuItem value="">Select Domain</MenuItem>
            {useConstantsQuery.data?.domains.map(domain => (
              <MenuItem key={domain.id} value={domain.name}>{domain.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText>{getError('settings.domain')}</FormHelperText>
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            name="settings.credentials.login"
            value={values.settings.credentials.login}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Amazon Relay Login"
            size="small"
            error={getIn(touched, 'settings.credentials.login') && getIn(errors, 'settings.credentials.login')}
            helperText={getIn(touched, 'settings.credentials.login') && getIn(errors, 'settings.credentials.login')}
            required
          />
        </FormControl>
        <FormControl>
          <TextField
            fullWidth
            name="settings.credentials.password"
            value={values.settings.credentials.password}
            onChange={handleChange}
            onBlur={handleBlur}
            label="Amazon Relay Password"
            size="small"
            error={getIn(touched, 'settings.credentials.password') && getIn(errors, 'settings.credentials.password')}
            helperText={getIn(touched, 'settings.credentials.password')
              && getIn(errors, 'settings.credentials.password')}
            required
          />
        </FormControl>
        {!isUpdating
          && (
            <>
              <FormControl fullWidth variant="outlined" size="small">
                <InputLabel>How do you run?</InputLabel>
                <Select
                  name="settings.carrier_type"
                  value={values.settings.carrier_type}
                  label="How do you run?"
                  onChange={handleChange}
                  required
                >
                  <MenuItem key="Local" value="local">Local</MenuItem>
                  <MenuItem key="Regional" value="regional">Regional</MenuItem>
                  <MenuItem key="OTR" value="otr">OTR</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth variant="outlined" size="small">
                <TextField
                  label={carrierDescLabel}
                  minRows={2}
                  multiline
                  name="settings.carrier_description"
                  value={values.settings.carrier_description}
                  onChange={handleChange}
                  required
                  error={getIn(touched, 'settings.carrier_description')
                    && getIn(errors, 'settings.carrier_description')}
                  helperText={getIn(touched, 'settings.carrier_description')
                    ? getIn(errors, 'settings.carrier_description')
                    : `${carrierDescHelperText}. (This will impact your load booking chances)`}
                />
              </FormControl>
            </>
          )}
      </Stack>
    </form>
  );
};
